<template>
  <div>
    <!-- <div class="nav">
			<div class="layout-nav">
				<div class="nav-list dis-flex space-between margin-auto-0">
					<div class="nav-list-l dis-flex">
						<div class="nav-list-ls">
						</div>
						<ul class="head-lists dis-flex">
							<li class="head-navba">
							</li>
							<li class="head-navba">
							</li>
							<li class="head-navbaac">
								作品管理
							</li>
							<li class="head-navba">
							</li>
						</ul>
					</div>

				</div>
			</div>
		</div> -->
    <secnav></secnav>

    <div class="class_nav"><span>当前位置：</span> <span>作品管理</span></div>

    <!-- 搜索输入 ---------------------------------------------------------->
    <div class="course-nav dis-flex">
      <div class="course-nav-item dis-flex">
        <h3>班级：</h3>
        <div>
          <el-select v-model="class_value" placeholder="请选择班级">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="course-nav-item dis-flex">
        <h3>姓名：</h3>
        <div>
          <el-input v-model="name" placeholder="请输入姓名"></el-input>
        </div>
        <div class="sousuo cursor-pointer" @click="shousuo">搜索</div>
      </div>
    </div>

    <!-- 列表内容 ---------------------------------------------------------------------------------------->
    <!-- 		<ul v-if="works.length>0" class="class_list dis-flex">
			<li class="class_item cursor-pointer" v-for="(item,index) in works" :key="index">
				<div @click="toworks(item)" class="item_img">
					<img :src="item.cover_img" alt="">
				</div>
				<h3>{{ item.title }}</h3>
				<h4 style="margin: 15px 0 0 15px">{{ item.student_name }}</h4>
				<div class="dis-flex space-between">
					<p>
						<span style="color: #000000;font-size: 16px;
           font-weight: 400">{{ item.works_num }}</span>&nbsp张作品
					</p>
					<div class="upzp" @click="upzuoping(item)">上传作品</div>
				</div>
			</li>
		</ul> -->
    <ul v-if="works.length > 0" class="class_list dis-flex">
      <li
        class="class_item cursor-pointer"
        v-for="(item, index) in works"
        :key="index"
      >
        <div class="img" @click="toworks(item)">
          <img v-if="item.headimg" :src="item.headimg" />
          <img v-else src="../../assets/images/headImg.png" />
        </div>
        <div class="name" @click="toworks(item)">
          <p><span>姓名：</span>{{ item.student_name }}</p>
          <p><span>学籍号：</span>{{ item.student_no }}</p>
        </div>
        <a class="btn1" @click="toworks(item)">点击查看 > </a>
        <a @click="upzuoping(item)" class="btn2">上传作品 > </a>
      </li>
    </ul>

    <div v-else class="wushuju">
      <img src="../../assets/images/wushuju.png" alt="" />
      <!--    <p class="wushuju-text">暂无数据</p>-->
    </div>
    <!---------------表单分页-------------------------->
    <div class="block clear" style="text-align: center; margin: 30px 20px 0 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        :total="pageCounts"
        layout="prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/service/api/api";
import secnav from '@/components/secnav/secnav'

export default {
  name: "course",
  components:{
	  secnav
  },
  data() {
    return {
      works: [
        // {id:'',img:'',name:'2021油画公开课01',text:''}
      ],
      currentPage: 1,
      pageCounts: 0,
      page_num: 1,
      class_value: "",
      options: [],
      name: "",
    };
  },
  created() {
    this.classinit();
  },
  methods: {
    // 作品列表init
    worksinit() {
      let params = {
        class_id: this.class_value,
        student_name: this.name,
      };
      api.worksinit(params).then((res) => {
        if (res.errcode === 0) {
          this.works = res.data;
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },
    // 班级初始化接口
    classinit() {
      api.classinit().then((res) => {
        if (res.errcode === 0) {
          this.class_value = res.data[0].id;
          this.options = res.data;
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
        this.worksinit();
      });
    },
    // 分页每页条数
    handleSizeChange(val) {
      // this.page_num = val
      // console.log(val)
      // console.log(`每页 ${val} 条`);
    },
    // 分页当前页
    handleCurrentChange(val) {
      this.page_num = val;
      this.currentPage = val;
      // console.log(`当前页: ${val}`);
    },
    // 作品详情
    toworks(item) {
      this.$router.push({
        path: "/works",
        query: {
          classmember_id: item.classmember_id,
          title: item.title,
          course_id: item.course_id,
          headimg: item.headimg,
          student_name: item.student_name,
          student_no: item.student_no,
        },
      });
    },
    // 搜索
    shousuo() {
      this.worksinit();
      console.log(this.name);
    },
    // 上传作品跳转
    upzuoping(item) {
      console.log(item);
      this.$router.push({
        path: "/upworks",
        query: {
          classmember_id: item.classmember_id,
          title: item.title,
          class_id: item.class_id,
          course_id: item.course_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #fff1f1 0%, #fdf9e9 100%);
  opacity: 0.5;
  /*margin-top: -20px;*/
}

.class_nav {
  width: 65%;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #ff8e42;
}

.class_nav span:first-child {
  color: #999999;
}

.course-nav {
  width: 65%;
  margin: 20px auto;
  height: 40px;
  line-height: 40px;
  /*background-color: #4A80F7;*/
}

.course-nav-item {
  color: #999999;
  font-size: 16px;
  margin-right: 20px;
}

.sousuo {
  width: 85px;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  color: #ffffff;
  text-align: center;
  background: #ff8e42;
  border-radius: 4px;
}

/* 列表样式 ********************************************************* */
.class_list {
  width: 65%;
  display: flex;
  min-height: 500px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.class_item {
  width: 160px;
  height: 310px;
  background: #ffffff;
  box-shadow: 0px 0px 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}

.class_item .img {
  text-align: center;
  margin: 20px 0;
}

.class_item .img img {
  width: 90px;
  height: 90px;
  border-radius: 20px 0 20px 0;
}

/* .item_img {
  width: 340px;
  height: 152px;
}

.item_img > img {
  width: 100%;
  height: 100%;
} */

.class_item p {
  text-align: center;
  /* margin-left: 14px; */
  margin-top: 4px;
  color: #222;
  font-size: 14px;
  font-weight: bold;
}

.class_item p span {
  color: #666;
  font-size: 14px;
  font-weight: bold;
}

.btn1 {
  text-align: center;
  width: 100px;
  height: 32px;
  display: block;
  line-height: 32px;
  background: #ff8e42;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  margin-top: 30px;
}

.btn2 {
  text-align: center;
  width: 100px;
  height: 32px;
  display: block;
  line-height: 32px;
  border: 1px solid #ff8e42;
  background: #fff;
  margin: 0 auto;
  border-radius: 5px;
  color: #ff8e42;
  margin-top: 10px;
}

.upzp {
  width: 80px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-right: 15px;
  background: #ff8e42;
  border-radius: 4px;
}

.wushuju {
  width: 235px;
  height: 600px;
  margin: 100px auto;
}

.wushuju > img {
  width: 235px;
  height: 196px;
}

.wushuju-text {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #999999;
  font-size: 22px;
  margin-top: 20px;
}

.layout-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-top: -4px;
}

.nav-list {
  width: 80%;
  height: 60px;
}

.nav-list-l {
  width: 586px;
  /*height: 24px;*/
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #6094ff;
}

.nav-list-ls {
  width: 86px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  /*margin-top: 5px;*/
  color: #6094ff;
}

.nav-list-ls > img {
  width: 86px;
  height: 24px;
  margin-top: 16px;
}

.head-navbaac {
  width: 84px;
  height: 60px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border-bottom: 2px solid #ff7800;
}

.head-navba {
  width: 84px;
  height: 60px;
  margin: 0 10px;
}

.head {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
}

.head > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-name {
  min-width: 42px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
</style>
